import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import DownshiftThree from "../components/Category"

const ContentContainer = styled.div`
  position: relative;
  a {
    color: inherit;
    cursor: pointer;
  }
`
const ItemContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 3.2vw 30px;
  line-height: 24px;
  .mqn2-aii {
    color: #7e7e7e;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    cursor: pointer;
  }
  .mqn2-aij {
    background-color: #fff;
    overflow: hidden;
    padding-top: 100%;
    position: relative;
    width: 100%;
    border-radius: 6px;
  }
  .mqn2-aik {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in;
    box-shadow: rgba(15, 15, 15, 0.1) 0px 1px 2px,
      rgba(222, 222, 222, 0.3) 0px 0px 0px 1px inset;
  }
  .mqn2-ail {
    opacity: 1;
    pointer-events: auto;
  }
  img {
    padding: 40px 7%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: transform 0.1s ease-in;
    max-width: 100%;
  }
  img:hover {
    transform: scale(1.047);
  }
  .mqn2-aiu {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 15px;
    .brand-name {
      cursor: default;
      font-size: 11px;
      line-height: 16px;
      text-transform: uppercase;
    }
    .product-name {
      cursor: pointer;
      font-size: 18px;
      color: #111;
      font-weight: 500;
      margin-bottom: 10px;
      text-decoration: underline;
    }
    .price-group {
      display: flex;
      padding-bottom: 10px;
      cursor: default;
      p {
        font-weight: 500;
      }
    }

    .product-original-price {
      text-decoration: line-through;
      color: #555;
      margin-right: 8px;
    }
    .product-sale-price {
      color: #333;
      font-weight: 700 !important;
      margin-right: 15px;
    }
    .product-sale-savings {
      color: rgb(235, 87, 87);
    }
    .product-description {
      cursor: default;
    }
  }
  p {
    margin-bottom: 0;
  }
  @media screen and (max-width: 425px) {
    grid-template-columns: 1fr !important;
    width: 380px;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr !important;
    width: 380px;
  }
`
const Category = styled.div`
  padding-top: 12px;
  padding-bottom: 8px;
  cursor: default;
  p {
    padding-right: 8px;
    padding-left: 8px;
    margin-right: 8px;
    border-radius: 4px;
    text-decoration: none;
    font-size: 14px;
    transition: all 0.1s ease-in-out;
    text-transform: capitalize;
    line-height: 26px;
    height: 26px;
    display: inline-block;
    background-color: #eee;
  }
  /* p:hover {
    background-color: #e1e1e1;
    color: #111;
  } */
`
const MainBtn = styled.div`
  position: absolute;
  right: 0;
  padding-bottom: 12px;
  a {
    cursor: pointer;
    user-select: none;
    transition: background 120ms ease-in 0s;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    height: 36px;
    border-radius: 3px;
    color: rgb(235, 87, 87);
    font-size: 14px;
    line-height: 1;
    padding-left: 12px;
    padding-right: 12px;
    background: rgba(235, 87, 87, 0.06);
    font-weight: 500;
    box-shadow: rgba(15, 15, 15, 0.1) 0px 1px 2px,
      rgba(235, 87, 87, 0.3) 0px 0px 0px 1px inset;
  }
  a:hover {
    background: rgba(235, 87, 87, 0.12);
  }
  .savings {
    display: inline;
    position: relative;
    z-index: 2;
    float: right;
    cursor: pointer;
    user-select: none;
    transition: background 120ms ease-in 0s;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    height: 27px;
    border-radius: 3px;
    color: #fff;
    font-size: 14px;
    line-height: 1;
    padding-left: 5px;
    padding-right: 5px;
    background: rgb(235, 87, 87);
    font-weight: 500;
    margin-top: 8px;
    margin-right: 8px;
  }
`
const TopContent = styled.div`
  padding-top: 120px;
  width: 580px;
  padding-bottom: 50px;
  @media (max-width: 425px) {
    width: 380px !important;
    h2 {
      width: 380px !important;
    }
    p {
      width: 125px !important;
    }
  }
  @media (max-width: 768px) {
    width: 380px !important;
    h2 {
      width: 380px !important;
    }
    p {
      width: 127px !important;
    }
  }
  h1 {
    font-size: 3.2rem;
    line-height: 1;
    margin-bottom: 20px;
  }
  h2 {
    font-size: 1.2rem;
    line-height: 1.2;
    font-weight: 500;
    width: 520px;
    color: #6b6b6b;
  }
  p {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    /* white-space: nowrap; */
    height: 36px;
    border-radius: 3px;
    color: rgb(0, 174, 3);
    font-size: 14px;
    line-height: 2.5;
    padding-left: 12px;
    padding-right: 12px;
    background: rgba(87, 235, 131, 0.1);
    font-weight: 500;
    box-shadow: rgba(15, 15, 15, 0.1) 0px 1px 2px,
      rgba(52, 166, 80, 0.3) 0px 0px 0px 1px inset;
    width: 121px;
    span {
      color: #101010;
      font-weight: 700;
    }
  }
`
const SortContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr !important;
  grid-gap: 3.2vw 30px;
  line-height: 24px;
  .label-select {
    display: block;
    cursor: pointer;
  }
  .da-hidden {
    background-color: #fff;
    border: 0;
    box-sizing: border-box;
    clip: rect(1px, 1px, 1px, 1px);
    clip: rect(1px 1px 1px 1px);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    position: absolute;
    text-align: center;
    top: 0;
    width: 1px;
  }
  .select_button {
    display: inline-block;
  }
  .button_root {
    -webkit-appearance: button;
    padding-right: 36px;
    text-align: left;
    position: relative;
    background: transparent;
    border-radius: 0;
    color: #202020;
    height: auto;
    padding: 0;
    border: 0;
    cursor: pointer;
    font-size: 16px;
    transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
    white-space: nowrap;
  }
  svg {
    overflow: hidden;
    right: 0;
  }
`
const SortButtons = styled.div`
  text-align: right;
  .sort-option {
    cursor: pointer;
    user-select: none;
    transition: background 120ms ease-in 0s;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    height: 36px;
    border-radius: 3px;
    color: #7e7e7e;
    font-size: 14px;
    line-height: 1;
    padding-left: 12px;
    padding-right: 12px;
    font-weight: 500;
    box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px,
      rgba(137, 137, 137, 0.3) 0px 0px 0px 1px inset;
    margin-bottom: 20px;
    margin-right: 8px;
  }
  .sort-option:hover {
    color: #111;
    box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px,
      rgba(0, 0, 0, 0.47) 0px 0px 0px 1px inset;
  }
`

const Ellipticals = () => (
  <StaticQuery
    query={graphql`
      query StaticDataEllipticalsQuery {
        allStaticdataJson(filter: { category: { eq: "Ellipticals" } }) {
          edges {
            node {
              id
              item_name
              brand
              image
              original_price
              sale_price
              savings_amount
              category
              description
              url
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <SEO title="Best Online Fitness Equipment Deals" />
        <TopContent>
          <h1>Get the best elliptical deals around the web</h1>
          <h2>
            We spend every day sifting through thousands of discounts to find
            you the best hassle-free deals around the web. Saving you ⏰ and 💰.
          </h2>
          <p>
            Updated <span>Today</span>
          </p>
        </TopContent>
        <ContentContainer>
          <ItemContainer>
            {data.allStaticdataJson.edges.map(edge => (
              <div className="mqn2-aii">
                <a href={edge.node.url} target="_blank">
                  <div className="mqn2-aij">
                    <div className="mqn2-aik ng-scope mqn2-ail">
                      <MainBtn>
                        <div className="savings">
                          Save ${edge.node.savings_amount}
                        </div>
                      </MainBtn>
                      <img
                        className=""
                        src={edge.node.image}
                        alt={
                          edge.node.brand +
                          " " +
                          edge.node.item_name +
                          " on sale!" +
                          " - $" +
                          edge.node.sale_price +
                          ".00"
                        }
                      />
                    </div>
                  </div>
                </a>
                <div className="mqn2-aiu">
                  <MainBtn>
                    <a href={edge.node.url} target="_blank">
                      Get This Deal
                    </a>
                  </MainBtn>
                  <a href={edge.node.url} target="_blank">
                    <p className="brand-name">{edge.node.brand}</p>
                  </a>
                  <a href={edge.node.url} target="_blank">
                    <p className="product-name">{edge.node.item_name}</p>
                  </a>
                  <div className="price-group">
                    <p className="product-original-price">
                      ${edge.node.original_price}.00
                    </p>
                    <p className="product-sale-price">
                      ${edge.node.sale_price}.00
                    </p>
                    <p className="product-sale-savings">
                      Save ${edge.node.savings_amount}!
                    </p>
                  </div>
                  <p className="product-description">{edge.node.description}</p>
                  <Category>
                    <p>{edge.node.category}</p>
                    <p>{edge.node.brand}</p>
                  </Category>
                </div>
              </div>
            ))}
          </ItemContainer>
        </ContentContainer>
      </Layout>
    )}
  />
)

export default Ellipticals
